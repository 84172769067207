<template lang='pug'>
  #UpSide
    loadingOverlay(v-if='loading || !checkOpt' message='Loading . . .' :zIndex='999' attach)
    #LoginPage(v-if='!token && !exp')
      .d-flex.align-center.justify-center(style='')
        div(style='margin-left:auto')
            v-btn.pr-0(color='#abaaaa' icon small).pr-3.pb-0.pt-4
                v-icon( size="18") mdi-minus
      #login
          .container
              .row
                  .col-12.col-md-6
                      img.pb-4(src='../../static/loginPage/images/logoface_1.svg', alt='' width='70px')
                      h4.f-text-highlight-word {{$t('UPMEETING.LOGIN')}}
                  .col-12.col-md-6
                      .login-box.pt-0.pl-0.pr-9(style='box-shadow: 0 0 40px rgba(255, 255, 255, 0.1);width:115vw;zoom:80%;padding:0 0 0 0;')
                          h4.f-text-highlight-word.mb-4 {{$t('LOGIN.LOGIN')}}
                          form
                              .input-group.mb-6
                                  v-text-field(v-model='username' :placeholder=`$t('LOGIN.ACCOUNT')` tabindex='1' outlined dense :autofocus='true')
                              .input-group.mb-6
                                  v-text-field(v-model='password' :placeholder=`$t('LOGIN.PWD')` tabindex='2' outlined dense :append-icon='iconType' :type="passwordType" @click:append="showPwd")
                              .input-group.mb-6(:class='displayNone ? "d-none" : ""')
                                  v-text-field(v-model='tenant' :placeholder=`$t('LOGIN.TENANT')` tabindex='3' outlined dense)
                              .input-group.mb-6(:class='!displayLoginVali ? "d-none" : ""')
                                  v-text-field(v-model='loginValiCode' :placeholder=`$t('LOGIN.LOGIN_VALI_CODE')` tabindex='4' outlined dense :append-icon='loginValiCodeIcon' :suffix=`$t('LOGIN.GET_VALI_CODE')` @click:append="sendLoginValiCode")
                              input.btn.hero-btn.w-100(type="submit", :value=`$t('LOGIN.LOGIN_UPGPT')` tabindex='5' @click='handleLogin($event, true)')
                              .d-block.text-center.my-4.pointer(@click='forgetPassword = true') {{$t('LOGIN.PASSWORD')}}
    #GPT.main-page.page-noScroll.pb-0.position-relative(v-else-if='mode=="side"' style='background-color: #FFF;height:100vh;')
      .d-flex.align-center.justify-center(style='')
        img.pointer(style='margin-left:33%' src='@/assets/images/UPGPT.png' width=100 )
        div(style='margin-left:auto')
            v-btn(icon small).pl-4.pb-1
                v-icon(color='#abaaaa' size="18") mdi-logout
            v-btn.pr-0(color='#abaaaa' icon small).pl-3.pb-1
                v-icon( size="18") mdi-minus
      v-row.mt-2(style='margin-right: -2px !important;max-height: 38px;')
        v-text-field.aisearch-bar(ref='ai_search_bar' v-model='aisearch' :loading='shot_loading? loagindColor.a:false' :rules='formRule.reqRules' :readonly='shot_loading' :prefix='aisearch_prefix' flat solo dense @keydown.enter='useQuseryString')
          template(v-slot:prepend)
            v-progress-circular.ai-loading-circular(color='#5bdeb5' size='32' width='2.5' :title='load_title' v-if='shot_loading' indeterminate style='bottom:3px;' )
              img.ai-shot-img.pointer.pt-1(src='@/assets/images/AI_C_small_ai.png' width=30)
            img.ai-shot-img.pointer(v-else src='@/assets/images/AI_C_small.png' width=30 @click="screenShot" :title="$t('UPMEETING.SHOT_BTN_TITLE')")
          template(v-slot:prepend-inner)
            img(src='@/assets/images/ann.png' width=30 style='margin-left: -6px;')
      v-row.scrollbar-upside.mt-5.pr-1(style='margin-right: -10px !important;display:block;')
        v-col.pa-0.pt-0.px-2.pb-1(cols='auto' style='z-index: 10;')
          v-avatar.ml-1(v-for='item,index in knowledgeList.slice(0,knowledgeList.length==10? 10:knowledgeListShowAll)' :key='item.id' :color='item.selected? "#14c6de":"#bac0c1"' size='24' rounded="0" :title='item.name' @click='selectKnowledge(item)').mb-1
            span.t-white() {{ item.name.slice(0,1) }}
          v-avatar.ml-1(v-if='knowledgeList.length > knowledgeDefaultLength+1' color='gary' size='24' rounded="0" @click='showAllKnowledge').mb-1
            span.t-white(style='font-size:11px;') {{ knowledgeListShowAll > knowledgeDefaultLength ? "—" : "+" + String(knowledgeList.length-knowledgeDefaultLength)}}
        v-col.d-flex.justify-center.align-center(v-if='QA_list.length==0' cols='12' style='flex-direction: column;margin-top: 20vh;')
            img(src='@/assets/images/upside_no_content.png' style='max-width:90vw;')
            div.text-center
                span {{$t('UPMEETING.NO_QA')}}
        v-col(cols='12' v-else style='margin-right: 0px !important;').pa-0.pt-0.pb-6
          v-card.mb-3(v-for='item,index in QA_list' :class='item.data_type =="answer"? "answer-card":"references-card"' :key='item.name' max-width='296' outlined flat)
            v-card-text.t-black(v-if='item.data_type =="answer"')
              vue-markdown.ellipsis-2.can-select-text.font-13-4.mb-1.f-text-highlight-word(:ref='item.id' :source='item.value')
              button.text-gray.font-13-4(ref='ans_show_more' v-if='item.more' @click='showMore(item, $event)') {{"show more＞"}}
            v-card-text.t-black(v-else)
              p.can-select-text.font-13-4.mb-1.f-text-highlight-word(v-if='item.read_all' v-html='item.value')
              p.can-select-text.font-13-4.mb-1.f-text-highlight-word(v-else v-html='item.highlight')
              button.text-gray.font-13-4(@click='showMore(item, $event)') {{"show more＞"}}
            v-card-text(v-if='item.data_type !="answer"').t-black.pb-0.pt-0 
              //- a.font-13-4.ellipsis(:title='item.resource' href="#" @click='openFile(item.resource_url)' style='text-decoration: underline !important;color:#3a75ff;') {{item.resource}}
              span.font-13-4.ellipsis(:title='item.resource' style='text-decoration: underline !important;color:#3a75ff;') {{item.resource}}
            v-card-text(v-if='item.data_type !="answer"').t-black.pt-1 
              v-icon(size='20' :color='getReferencesIocn(item.type).color') {{getReferencesIocn(item.type).icon}}
              span.font-13-4.pl-1.relation-score {{item.relation_score}}
            v-card-actions(v-if="item.data_type =='answer'")
              v-btn(icon absolute style='bottom:6px;right:6px;' small @click='copied? "":copyMsg(item.value)') 
                v-icon(v-if='!copied' size="18") mdi-content-copy
                v-icon(v-else size="18" color='green') mdi-check

      v-dialog(v-model='errorDialog' attach width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')

    #GPT.main-page.page-noScroll.pb-0.position-relative(v-else style='background-color: #FFF;height:100vh;')
      .d-flex.align-center.justify-center(style='')
        div(style='margin-left:auto')
            v-btn(icon small).pl-4.pb-1
                v-icon(color='#abaaaa' size="18") mdi-logout
            v-btn.pr-0(color='#abaaaa' icon small).pl-3.pb-1
                v-icon( size="18") mdi-minus
      v-row#page-head.d-flex.align-center.justify-md-space-between.upmeeting_select
        v-col.d-flex.align-center.pl-3(style='top:-20px' cols='12')
          img(src='../../static/loginPage/images/logoface_1.svg', alt='' width='30px')
          h4.pl-3.pt-2.f-text-highlight-word.my-0 {{$t('UPMEETING.ANN')}}
        v-col(style='top:-20px' cols="12").pt-0.pb-0
          p.d-flex.align-center.position-relative.input-has-label.input-has-label-no-border.ai_notebook_select
            label.pt-3
              h6.t-black.f-text-highlight-word.fontTeams {{$t('GPT_MEETINGRECAP.AN')}} :
            v-autocomplete.mt-1(
                v-model='ai_notebook'
                :items="ai_notebook_list",
                item-text="name",
                item-value="id", solo='solo'
                :menu-props='{closeOnClick:true}'
                dense='dense'
                @change='change_ai_notebook'
                return-object=true
                :readonly='isOver'
              )
              template(v-slot:selection="data")
                  span.small_word.pl-1(class='ellipsis' :title='data.item.name') {{data.item.name}}
          v-row(v-if='change_dialog').mt-2.warn-border.mb-1.fontTeams
            v-col(cols='2').pt-2
              div(style='height:30px;width:30px;background-color:#d6d5d5;border-radius: 50px;').d-flex.justify-center.align-cneter
                v-icon(color='#59abb6' size='20') mdi-alert
            v-col(cols='10').pt-2.pr-6
              span {{ $t("UPMEETING.CHANGE_AN") }}
              v-btn(style='margin-top:-10px;margin-right:-10px;' absolute top right icon :ripple='false' @click='onCancel("change_dialog")' width='18px' height='18px' color='cancel' depressed dark)
                v-icon(size='18' color='#d6d5d5') mdi-close
              v-row.d-flex.justify-space-between.pt-3
                  v-col(cols='6').pl-0
                      v-btn(:ripple='false' @click='onConfirm' width='100%' height='25' color='#59abb6' depressed )
                          .t-white().fontTeams {{$t('GENERAL.CONFIRM')}}
                  v-col(cols='6').pl-0
                      v-btn(:ripple='false' @click='onCancel("change_dialog")' width='100%' height='25' color='#d6d5d5' depressed dark) 
                        span(style='color:#b5b1b1;').fontTeams {{$t('GENERAL.CANCEL')}}
      #page-inner.scroll-x.pt-md-6.pb-0.height_100vh.fontTeams(style='margin-top:-25px !important;')
        .data-area.upmeeting_tab.pl-4
            v-tabs(v-model='tab' background-color='transparent'  id='gptupload')
                  v-tab(v-for='item in tabs' :key='item.id' :href='`#tab-${item.id}`'  style='display:flex; justify-content:center !important;')
                      span.span {{ item.name }}
                  v-tab-item.scrollbar.pr-2(key='ai_note' value='tab-1' :transition='false' :reverse-transition='false' :class='change_dialog? "upmeeting-low-height":"upmeeting-height"')
                    
                    v-row(v-if='keys_dialog').warn-border.mt-3.pb-0
                      v-col(cols='2').pt-2.pb-0
                        div(style='height:30px;width:30px;background-color:#d6d5d5;border-radius: 50px;').d-flex.justify-center.align-cneter
                          v-icon(color='#59abb6' size='20') mdi-lightbulb-outline
                      v-col(cols="10" :class='isOver? "pb-2":"pb-0"')
                        p {{complete_keys+"/"+fields.length}}{{$t('UPMEETING.NOTE_C')}}
                        p.pb-0.mb-0(v-if='isOver') {{$t('UPMEETING.MEETING_OVER')}}
                        a(v-if='isOver' @click='goToRecord' style='color:#518bf2;') {{meeting_data.name}}
                        v-btn(style='margin-top:-10px;margin-right:-10px;' absolute top right icon :ripple='false' @click='onCancel("keys_dialog")' width='18px' height='18px' color='cancel' depressed dark)
                          v-icon(size='18' color='#d6d5d5') mdi-close
                    v-row(v-for='item,index in fields' :key='item.column' style='width:263px;max-width:263px;' :class='index==0? "pt-6":"pt-3"')
                      p.d-flex.align-center.input-has-label.input-has-label-no-border-min-width.position-relative
                        label.ellipsis(style='width:70px;max-width:70px;' :title='item.name')
                          //- h6.t-black {{$t('ADD_AIBOTS.NAME')}} :
                          h6.f-text-highlight-word.t-black.ellipsis.fontTeams {{item.name}}
                        //- v-textarea.v-text-field_low_z-index.span.textarea-style(solo flat dense v-model='item.value' readonly style='padding-top:0.1rem;' no-resize rows='5') 
                        div.scrollbar(style='max-width:165px;min-width:165px;max-height:86px;') 
                          v-icon(v-if='item.type == "bol" && (item.value == true || item.value == "true")' size='20') mdi-checkbox-marked
                          v-icon(v-else-if='item.type == "bol" && (item.value != false || item.value != "false")' size='20') mdi-checkbox-blank-outline
                          span(v-else-if='item.type == "opt"') {{getOptStr(item)}}
                          span(v-else) {{item.value != "null"? item.value:""}}

                  v-tab-item.scrollbar(key='transcript' value='tab-2' :transition='false' :reverse-transition='false' :class='change_dialog? "upmeeting-low-height":"upmeeting-height"')
                    v-card.elevation-0
                      v-card-text.pa-0.pt-4
                          v-spacer.mt-10.small_space(v-for='item,index in messageData' :key='"msg-"+item.index' :ref="'msg-'+item.index" id="trackMsg" )
                              v-row.chat_icon_div.d-flex.justify-start(color="rgba(0,0,0,0)" flat class="mb-0")
                                  //- div.chat_icon_div(v-if='item.question')
                                  //-   v-btn.chat_icon_bottom(width='0' height='32' color='green' plain border-0 @click='onCopyMsg(item.question)') {{$t('GENERAL.COPY')}}
                                  v-row
                                      v-col(style='max-width:40px' justify='center' align='center')
                                          v-avatar(class="" size="28" elevation="10")
                                              v-icon.chat_icon_backgroung_color(:title=`item.name`) {{ item.name.slice(0,1) }}
                                          //- span(style='white-space:nowrap;font-size:0.75rem;') {{item.time}}
                                          //- span(style='white-space:nowrap;') {{getLittleFormatTime(item.time).slice(-5)}}
                                      v-col.pa-0.pl-4(style='max-width:210px;width:210px;margin-top:8px;')
                                          v-card.rounded-card(class="" color=""  flat)
                                              div.px-0.pb-3()
                                                  div.d-flex.justify-start.mb-0
                                                      span.fontTeams(style='font-weight:600;') {{item.name}} 
                                                      span.pl-3.fontTeams(style='white-space:nowrap;font-size:0.75rem;padding-top:1px;color:gray;') {{item.time}}
                                                  div.upchat-message-div.d-flex.justify-start.mb-4
                                                      span.can-select-text.fontTeams(v-html='highlight(item.text)' style='line-height:1.7')
                    //- v-divider.divider.ma-0
    v-dialog(v-model='forgetPassword' max-width='500')
        forgetPassword-dialog(@emitForgetPasswordDialog='onEmitForgetPasswordDialog' @emitForgetPasswordSuccess='onEmitForgetPasswordSuccess')
    v-dialog(v-model='lastmeetDialog' width='360' content-class='statusDialog' persistent)
      lastmeet-dialog(@emitLastmeetDialog='onEmitLastmeetDialog' :isGoogle='isGoogle')
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitMessageDialog(false)' @keydown.enter='onEmitMessageDialog(true)')
      message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
      success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' attach width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
      error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import { mapActions } from 'vuex';
import loadingOverlay from "@/components/Common/loadingOverlay";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import lastmeetDialog from "@/components/GPT_Dialog/UpMeet/lastmeetDialog";
import i18n from '/common/plugins/vue-i18n.js';
import UpSideService from "@/services/UpSideService";
// import PreferenceService from "@/services/PreferenceService";
import CustomFieldDataService from "@/services/CustomFieldDataService";
import VueMarkdown from 'vue-markdown-v2';
import LoginValidationService from '../services/LoginValidationService';
import forgetPasswordDialog from "@/components/Dialog/User/forgetPasswordDialog";
import passData from "@/services/passData.js";

function initialState (){
  return {
      // Dialog
      loading:false,
      messageDialog: false,
      message: "",
      successDialog: false,
      successMessage: "",
      errorDialog: false,
      errorMessage: "",
      lastmeetDialog: false,
      // Data
      change_dialog:false,
      keys_dialog:true,
      fields:[],
      tab:1,
      tabs:[
        {
            id: 1,
            key: 'ai_note',
            name: i18n.t('GPT_MEETINGRECAP.ANs')
        },
        {
            id: 2,
            key: 'transcript',
            name: i18n.t('GPT_MEETINGRECAP.TS')
        },
      ],
      complete_keys:0,
      isOver:false,
      current_ai_notebook:null,
      ai_notebook:null,
      ai_notebook_list:[],
      meeting_data:null,
      messageData:[],
      searchMsgList: [],
      jot_field_definition:'',
      meeting_summary:null,
      optList:{},
      checkOpt:true,
      isGoogle:false,
      mode:'side',
      QA_list:[],
      aisearch:'',
      copied:false,
      shot_loading:false,
      api_data:{api_key:null,tenant:null},
      aisearch_prefix:null,
      loagindColor:{a:"#f31a48",b:"#f43757",c:"#f55869",d:"#f67277",e:"#f78883"},
      formRule: {
            reqRules: [
                (v) => !!v&&!!v.trim() || i18n.t('RULE.RULE_R'),
            ],
      },
      load_title:i18n.t('UPMEETING.IMAGE_PREFIX'),
      // load and check
      username: '',
      password: '',
      tenant: '',
      loginValiCode: '',
      forgetPassword: false,
      passwordType: "password",
      iconType:"fa fa-eye-slash",
      loginValiCodeIcon: "fa fa-key",
      displayNone: false,
      displayLoginVali: false,
        
      token:null,
      exp:0,
      upMeetingService:null,
      userData:null,
      isLogout:false,
      knowledgeList:[],
      knowledgeListShowAll:9,
      knowledgeDefaultLength:9

    };
}
export default {
  components: {
    VueMarkdown,
    loadingOverlay,
    messageDialog,
    successDialog,
    errorDialog,
    lastmeetDialog,
    forgetPasswordDialog
  },
  data() {
    return initialState();
  },
  async created() {
    // if token in et
    window.addEventListener('message', this.loginMessage, false);
    parent.postMessage({
        isUpday:true,
        from: "UpMeeting_AUTO_Login",
        value: "try auto login"
    }, "*")

    window.addEventListener('message', this.receiveMessage, false);
  },
  destroyed() {
  },
  async mounted(){
      await this.check_more();
      passData.$on("UsideUnauthorized", () => {
          this.userData = null;
      })
  },
  methods: {
    // login--------------------------------------
    logout(){
      this.token = null;
      this.exp = 0;
      this.tenant = '';
      this.userData=null;
      this.upMeetingService = null;
      this.loading = false;
      parent.postMessage({
          isUpday:true,
          from: "UpSide_Logout",
          value: "byebye"
      }, "*")
      Object.assign(this.$data, initialState());
    },
    async loginMessage(e){
      if(e.data.from=="UpSide" && e.data.id=="UpSideLogin"){
        if(e.data.type=="userData"){
            this.userData = e.data.value.userData;
            window.removeEventListener('message', this.loginMessage, false);
        }else if(e.data.type=="no_localStorage"){
          // 
        }
        this.upsidLoading = false;
        }
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "text";
        this.iconType ="fa fa-eye"
      } else {
        this.passwordType = "password";
        this.iconType ="fa fa-eye-slash"
      }      
    },
    sendLoginValiCode() {
        if (this.username == null || this.username.trim() == '') {
            this.errorDialog = true;
            this.errorMessage = i18n.t('LOGIN.ERROR_4');
        } else {
            if (this.tenant != '') {
                this.loading = true;
                LoginValidationService.sendLoginValidationCode(this.tenant, this.username)
                .then(() => {
                    this.loading = false;
                    this.messageDialog = true;
                    this.message = i18n.t('LOGIN.SENT_VALI_CODE');
                }).catch(() => {
                    this.loading = false;
                    this.errorDialog = true;
                    this.errorMessage = i18n.t('LOGIN.LOGIN_VAL_ERROR');
                });
            }
        }
    },
    handleLogin(event) {
        event.preventDefault();
        if (this.tenant == ''|this.username == ''|this.password == '') 
        {
            this.errorDialog = true;
            if(this.username == ''|this.password == '')
            {
                this.errorMessage = i18n.t('LOGIN.ERROR');
                this.noneusername = true
            }else
            {
                this.errorMessage = i18n.t('LOGIN.ERROR_2');
            }
        }
        else 
        {
            let tenant = this.tenant.toLowerCase();
            this.loading = true;
            this.loginUpSide({ username: this.username, password: this.password, tenant: tenant, loginValiCode: this.loginValiCode })
                .then((response) => {
                    this.username = '';
                    this.password = '';
                    let data = response;
                    data['tenant'] = tenant
                    this.userData = data;
                })
                .catch((error) => { 
                    this.loading = false;
                    this.errorDialog = true;
                    let error_code = (this.displayLoginVali) ? 'LOGIN.ERROR_3_VALI_CODE' : 'LOGIN.ERROR_3';
                    if (error.response) {
                        let errorMessage = error.response.data.detail;
                        if (errorMessage == 'max_license_number')
                            this.errorMessage = i18n.t('MANAGEUSERS.ERROR');
                        else {
                            this.errorMessage = i18n.t(error_code);
                        }
                    } else {
                        this.errorMessage = i18n.t(error_code);
                    }
                })
                .finally(() => {
                })
        }
    },
    onEmitForgetPasswordDialog(val) {
        this.forgetPassword = val;
    },	
    onEmitForgetPasswordSuccess(val,errorMsg) {
        if(val){
            this.forgetPassword = false;
            this.successDialog = true;
        }else{
            this.errorDialog = true;
            this.errorMessage = errorMsg;
        }
        
    },
    ...mapActions([
      'loginUpSide',
    ]),
    // login--------------------------------------
    selectKnowledge(item){
      item.selected = !item.selected;
    },
    showAllKnowledge(){
      if(this.knowledgeListShowAll>this.knowledgeDefaultLength){
        this.knowledgeListShowAll = this.knowledgeDefaultLength;
      }else{
        this.knowledgeListShowAll = this.knowledgeList.length;
      }
    },
    resetAIStatus(){
      this.aisearch_prefix = null;
      this.shot_loading = false;
      this.load_title = i18n.t("UPMEETING.IMAGE_PREFIX");
      this.loagindColor = {a:"#f31a48",b:"#f43757",c:"#f55869",d:"#f67277",e:"#f78883"};
    },
    changeAnalysisLoadColor(color){
      if(color == "yellow"){
        this.aisearch_prefix = i18n.t("UPMEETING.QUERY_PREFIX");
        this.load_title = i18n.t("UPMEETING.QUERY_PREFIX");
        this.loagindColor = {a:"#faea6c",b:"#f9e858",c:"#f8e330",d:"#f6de10",e:"#f4d900"}
      }else if(color == "green"){
        this.load_title = i18n.t("UPMEETING.SEARCH_PREFIX");
        this.loagindColor = {a:"#20e160",b:"#20e1af",c:"#20bae1",d:"#02a2f8",e:"#00eeff"}
      }
    },
    async getAnalysisAnswer(data,error){
      if(error || data.answer==""){
        this.resetAIStatus();
        return;
      }
      this.processQAData(data);
      this.resetAIStatus();
    },
    async getAnalysisQueryString(data,error){
      if(error){
        this.resetAIStatus();
        if(data){
          this.showErrorDialog(i18n.t('UPMEETING.BACKEND_ERROR'));
        }else{
          this.showErrorDialog(i18n.t('UPMEETING.IMAGE_ERROR'));
        }
        return;
      }
      this.aisearch_prefix = null;
      this.aisearch = data
    },
    async getAnalysisImage(data,error){
      if(error || !data ){
        this.showErrorDialog(i18n.t('UPMEETING.IMAGE_ERROR'));
        this.resetAIStatus();
        return;
      }
    },
    async screenShot(){
      this.shot_loading = true;
      this.aisearch_prefix = i18n.t("UPMEETING.IMAGE_PREFIX");
      this.aisearch = null;
      await this.getApiDataAndCallApi("ScreenShot",this.aisearch);
    },
    async useQuseryString(){
      if(this.aisearch && this.$refs.ai_search_bar.validate()){
        this.shot_loading = true;
        this.aisearch_prefix = i18n.t("UPMEETING.IMAGE_PREFIX");
        await this.getApiDataAndCallApi("ScreenShot",this.aisearch);
      }
    },
    async getApiDataAndCallApi(from,query){
      if(this.QA_list.length>0 && !this.$refs["answer001"][0].$el.classList.contains("ellipsis-2")){
        this.$refs["ans_show_more"][0].click();
      }
      if(!this.api_data.api_key && !this.api_data.tenant){
        await this.upMeetingService.getApiKey().then((response)=>{
          if(response.data == ""){
            return;
          }
          this.api_data.api_key = response.data;
          this.api_data.tenant = this.tenant;
        })
      }
      let knowledge_ids = this.knowledgeList.filter(el=>el.selected).map((el)=>{if(el.selected){return el.id}});
      this.api_data.knowledge_ids = knowledge_ids;
      parent.postMessage({isUpday:true,from: from,key: this.api_data,data:query}, "*")
      if(query){
        this.aisearch = null;
      }
    },
    openFile(url){
        window.open(url);
    },
    getReferencesIocn(data){
        switch(data){
            default:
                return {icon:'mdi-earth',color:'blue'}
        }
    },
    async processQAData(data){
      this.QA_list.length = 0;
      let answer = {
        id:"answer001",
        value:data.answer,
        data_type:"answer",
        more:false,
      }
      this.QA_list.push(answer);
      data.references.forEach((el,index) => {
        let resource = {
          id:"references"+index,
          value:el.context,
          highlight:el.highlight,
          data_type:"references",
          more:false,
          resource:el.resource,
          resource_url:'https://www.upday.ai/',
          relation_score:el.relation_score,
          type:el.type? el.type:"KA",
          read_all:false
        }
        this.QA_list.push(resource);
      })
      setTimeout(async ()=>{
        await this.check_more();
      },0)
    },
    copyMsg(msg){
      parent.postMessage({isUpday:true,from: "CopyText",data:msg}, "*")
      if(this.copied == false){
        this.copied = true;
        setTimeout(()=>{
          this.copied = false;
        },1000)
      }
    },
    async check_more(){
      if(this.QA_list.length>0){
        for(let i of this.QA_list){
          if(i.id=="answer001" && this.isEllipsisActive(i.id)){
            i.more = true;
            break;
          }
        }
      }
    },
    isEllipsisActive(id){
      let el = this.$refs[id][0].$el;
      let width       = el.offsetHeight;
      let widthChild  = el.scrollHeight;
      return (widthChild > width);
    },
    showMore(item,e){
      if(item.data_type=="answer"){
        this.$refs[item.id][0].$el.classList.toggle("ellipsis-2");
      }
      if(e.target.innerText == "show more＞"){
        if(item.data_type=="references"){
          item.read_all = true;
        }
        e.target.innerText = "show less＜"
      }else{
        if(item.data_type=="references"){
          item.read_all = false;
        }
        e.target.innerText = "show more＞"
      }
    },
    getOptStr(item){
      return item.value && this.optList[item.column][item.value] ? this.optList[item.column][item.value] : "";
    },
    async receiveMessage(e){
      if(e.data.from=="UpSide"){
        // if(e.data.type=="Language"){
        //   i18n.locale = e.data.lang
        // }
        if(e.data.id=="NoUserData"){
          this.token = null;
          this.exp = 0;
          this.tenant = '';
          this.userData=null;
          this.upMeetingService = null;
          this.loading = false;
        }
        if(e.data.id=="UpSideLogout"){
          this.isLogout = true;
          this.showMessageDialog("您確定要登出嗎?");
        }
        if(e.data.type=="Create New"){
          // console.log("開始建立新Meeting Recpa")
          await this.upMeetingService.createMeetingRecap().then((response)=>{
            this.meeting_data = response.data;
            this.ai_notebook = this.meeting_data.ai_notebook_id;
            this.current_ai_notebook = this.meeting_data.ai_notebook_id;
            this.fields = this.getFields();
          }).then(()=>{
            this.get_jot_field_definition();
            this.updateJotData();
            this.updateFieldComplete();
          }).finally(()=>{
            this.loading = false;
            if(e.data.title){
              let editData = {
                "id":this.meeting_data.id,
                "name":e.data.title
              }
              this.upMeetingService.editMeetingRecap(editData).then((response)=>{
                this.meeting_data.name = response.data.name;
              })
            }
            parent.postMessage({
                isUpday:true,
                from: "UpMeeting_Create",
                value: this.meeting_data.id
            }, "*")
          })
        }else if(e.data.type=="Google Last Meet"){
          if(this.meeting_data == null){
            await this.upMeetingService.getMeetingRecapDetail(e.data.id).then((response)=>{
                this.meeting_data = response.data;
                this.ai_notebook = this.meeting_data.ai_notebook_id;
                this.current_ai_notebook = this.meeting_data.ai_notebook_id;
                this.fields = this.getFields();
                this.messageData = this.meeting_data.transcript;
                this.meeting_summary = this.meeting_data.meeting_summary;
                this.updateJotData(this.meeting_data.ai_notes);
                this.updateFieldComplete();
                this.process_msg();
            }).then(()=>{
              this.get_jot_field_definition();
            }).finally(()=>{
              this.loading = false;
              parent.postMessage({
                  isUpday:true,
                  from: "UpMeeting_Last_Meet",
                  value: this.meeting_data.id
              }, "*")
            })
          }
        }else if(e.data.type=="Record"){
          // console.log("收到文字記錄",e.data)
          if(this.meeting_data == null){
            await this.upMeetingService.getMeetingRecapDetail(e.data.id).then((response)=>{
              // console.log("取得Meeting Recap資料")
                this.meeting_data = response.data;
                this.ai_notebook = this.meeting_data.ai_notebook_id;
                this.current_ai_notebook = this.meeting_data.ai_notebook_id;
                this.fields = this.getFields();
                this.messageData = this.meeting_data.transcript;
                this.meeting_summary = this.meeting_data.meeting_summary;
                this.updateJotData(this.meeting_data.ai_notes);
                this.updateFieldComplete();
                this.process_msg();
            }).then(()=>{
              this.get_jot_field_definition();
            }).finally(()=>{
              this.loading = false;
              // console.log("取得meeting recap後分析文字記錄")
              this.sendToJot(e.data.record_data,e.data.use_last_record);
            })
          }else{
            // console.log("分析文字記錄")
            this.sendToJot(e.data.record_data,e.data.use_last_record);
          }
        }else if(e.data.type=="Meeting Over"){
          if(e.data.id == "Google"){
            this.isGoogle = true;
          }
          this.isOver = true;
          this.keys_dialog = true;
          this.lastmeetDialog = true;
        }else if(e.data.type=="Change Mode"){
          if(e.data.mode == "meet error"){
            if(this.meeting_data){
              this.mode = e.data.mode;
              parent.postMessage({
                isUpday:true,
                from: "has meeting and change icon",
                value:"has meeting and change icon"
              }, "*")
            }else{
              this.showErrorDialog(i18n.t('UPMEETING.CAPTION_ERROR'));
            }
            return;
          }
          this.mode = e.data.mode;
          if(this.meeting_data){
            this.updateJotData();
            this.process_msg();
            parent.postMessage({
              isUpday:true,
              from: "Check_Meeting_OR_NOT",
              value:"has_meeting_now"
            }, "*")
          }else{
            parent.postMessage({
              isUpday:true,
              from: "Check_Meeting_OR_NOT",
              value:"no_meeting_now"
            }, "*")
          }
        }else if(e.data.type=="Get_ScreenShot_Data"){
          await this.getAnalysisImage(e.data.data,e.data.error);
          this.aisearch_prefix = null;
        }else if(e.data.type=="Get_Query_String"){
          await this.getAnalysisQueryString(e.data.data,e.data.error);
        }else if(e.data.type=="Get_Analysis_Answer"){
          await this.getAnalysisAnswer(e.data.data,e.data.error);
        }
        else if(e.data.type=="Change_Analysis_Color"){
          await this.changeAnalysisLoadColor(e.data.data);
        }
      }
    },
    async get_jot_field_definition(){
      // let df = JSON.parse(JSON.stringify(this.current_ai_notebook.field_definition));
      // if(this.current_ai_notebook.record_type == "ai_notes"){
      //   for(let i of df){
      //     i.format_type = i.format_type +" , "+ i.description;
      //   }
      // }
      switch(this.current_ai_notebook.record_type){
        case "ai_notes":
          this.jot_field_definition = this.current_ai_notebook.field_definition.ai_notes;
          break;
        case "opportunity":
          this.jot_field_definition = this.current_ai_notebook.field_definition.opportunity;
          break;
        default:
          this.jot_field_definition = [];
          break;
      }
      if(this.current_ai_notebook.record_type == "ai_notes"){
        for(let i of this.jot_field_definition){
          if(i.column_id.slice(0,3) == "opt"){
            this.optList[i.column_id] = i.option_set;
          }
        }
        this.checkOpt = true;
      }else if(this.current_ai_notebook.record_type == "opportunity"){
          await CustomFieldDataService.getCustomFieldDetail("Opportunity").then((response)=>{
            let cf_data = response.data[0].definition;
            let cf_data_list = cf_data.filter(el=>el.stage_type_id==this.current_ai_notebook.business_process_id || el.stage_type_id == "all");
            cf_data_list.forEach((el)=>{
                for (const [key, value] of Object.entries(el.data)){
                    if(key.slice(0,3)=="opt"){
                      this.optList[key] = value.option_set;
                    }
                }
            })
            for(let i of this.jot_field_definition){
              if(i.column_id && i.column_id.slice(0,3) == "opt"){
                i.option_set = this.optList[i.column_id];
              }
            }
          })
          this.checkOpt = true;
      }
    },
    async sendToJot(record,use_last_record){
      let ori_ai_notes = this.meeting_data.ai_notes;
      let field_definition = [];
      if(ori_ai_notes == null){
          ori_ai_notes = {};
          switch(this.current_ai_notebook.record_type){
              case "ai_notes":
                  field_definition = this.current_ai_notebook.field_definition['ai_notes'];
                  field_definition.forEach((el)=>{
                      ori_ai_notes[el.column_name] = null;
                  })
                  break;
              case "opportunity":
                  field_definition = this.current_ai_notebook.field_definition['opportunity'];
                  field_definition.forEach((el)=>{
                      ori_ai_notes[el.column_name] = null;
                  })
                  break;
              default:
                break;
          }
      }else{
          let column_name_for_key = {};
          switch(this.current_ai_notebook.record_type){
              case "opportunity":
                  field_definition = this.current_ai_notebook.field_definition['opportunity'];
                  field_definition.forEach((el)=>{
                      if(el.source_type == "custom_field"){
                          column_name_for_key[el.column_name] = ori_ai_notes[el.column_id];
                      }else if(el.source_type == "original"){
                          column_name_for_key[el.column_name] = ori_ai_notes[el.column_name];
                      }
                  })
                  break;
              case "ai_notes":
                  field_definition = this.current_ai_notebook.field_definition['ai_notes'];
                  field_definition.forEach((el)=>{
                      column_name_for_key[el.column_name] = ori_ai_notes[el.column_id];
                  })
                  break;
              default:
                break;
          }
          ori_ai_notes = column_name_for_key;
      }
      let data = {
        "meeting_recap_id":this.meeting_data.id,
        "ai_notebook_id":this.current_ai_notebook.id,
        "field_definition":this.jot_field_definition,
        "transcript":record? record:[],
        "language":"Tradionnal Chinese",
        "ori_ai_notes":ori_ai_notes,
        "record_type":this.current_ai_notebook.record_type,
        "meeting_summary":this.meeting_summary,
        "use_last_record":use_last_record == undefined? false : use_last_record
      }
      let key = "";
      await this.upMeetingService.getApiKey().then((response)=>{
        if(response.data == ""){
          return;
        }
        key = response.data;
      })
      await this.upMeetingService.JotAINoteBook(data,key).then((response)=>{
        this.messageData = response.data.transcript;
        this.meeting_data.ai_notes = response.data.openai_response_ai_notes;
        this.meeting_summary = response.data.meeting_summary;
        this.updateJotData(response.data.openai_response_ai_notes);
        this.updateFieldComplete();
        this.process_msg();
      })
    },
    change_ai_notebook(){
      if(this.current_ai_notebook.id != this.ai_notebook.id){
        this.change_dialog = true;
      }else{
        this.change_dialog = false;
      }
    },
    async getAiNoteBookList(){
      await this.upMeetingService.getAiNotebookList().then((response)=>{
        this.ai_notebook_list = response.data;
      })
    },
    async onConfirm(){
      this.current_ai_notebook = this.ai_notebook;
      this.get_jot_field_definition();
      this.isEditSub = false;
      this.change_dialog = false;
      this.fields = this.getFields();
      let edit_data = {
        "id" : this.meeting_data.id,
        "name": this.meeting_data.name,
        "ai_notebook_id_id": this.current_ai_notebook.id,
        "ai_notes":null,
        "record_type":this.current_ai_notebook.record_type,
      }
      await this.upMeetingService.editMeetingRecap(edit_data).then((response)=>{
        this.meeting_data = response.data;
      })
      this.updateFieldComplete();
    },
    onCancel(val){
      if(val=="change_dialog"){
        this.ai_notebook = this.current_ai_notebook;
        this.change_dialog = false;
      }else if(val=="keys_dialog"){
        this.keys_dialog = false;
      }
    },
    onMail: function (mail) {
      return "mailto:" + mail;
    },
    updateFieldComplete(){
      this.complete_keys = this.fields.reduce((a,b)=>{
        if(b.value!=null){
          a=a+1;
        }
        return a},0)
    },
    updateJotData(data){
      if(data){
        for(let i of this.fields){
          i.value = data[i.column];
        }
      }
    },
    getFields(){
      let opptyField = [
        {
          name:i18n.t('DEAL_DETAIL.EXPECTED_CLOSE_DATE'),
          column:"expected_close_date",
          value:null,
          type:"dat",
        },
        {
          name:i18n.t('DEAL_DETAIL.CLIENT_BUDGET'),
          column:"customer_budget",
          value:null,
          type:"str",
        },
        {
          name:i18n.t('TO_DEAL.BUDGET'),
          column:"budget_confirmed",
          value:null,
          type:"bol",
        },
        {
          name:i18n.t('DEAL_DETAIL.CUSTOMER_NEEDS'),
          column:"customer_needs",
          value:null,
          type:"str",
        },
      ]
      let data = [];
      let field_definition = [];
      switch(this.current_ai_notebook.record_type){
        case "ai_notes":
            field_definition = this.current_ai_notebook.field_definition['ai_notes'];
            field_definition.forEach((el)=>{
              data.push(
                      {
                        name:el.column_name,
                        column:el.column_id,
                        value:null,
                        type:el.column_id.slice(0,3)
                      }
                    );
            })
            break;
        case "opportunity":
            field_definition = this.current_ai_notebook.field_definition['opportunity'];
            field_definition.forEach((el)=>{
                if(el.source_type == "custom_field"){
                    data.push(
                      {
                        name:el.column_name,
                        column:el.column_id,
                        value:null,
                        type:el.column_id.slice(0,3)
                      }
                    );
                }else if(el.source_type == "original"){
                    let oppty_f = opptyField.filter(o=>el.column_name==o.column);
                    if(oppty_f.length>0){
                      data.push(oppty_f[0])
                    }
                }
            })
            break;
        default:
          break;
      }
      return data;
    },
    goToRecord(){
      window.open("/upgpt/MeetingRecap/"+this.meeting_data.id, '_blank').focus();
    },
    highlight(text) {
        if(this.searchMsgList.length == 0) {
            return text;
        }
        return text.replace(new RegExp(this.lastSearchMsg, "gi"), match => {
            return '<span class="highlight">' + match + '</span>';
        });
    },
    process_msg(){
        if(this.messageData){
            try{
                this.messageData = JSON.parse(this.messageData);
            }catch{
                // 
            }
        }else{
          this.messageData = []
        }
        let index = -1;
        this.messageData.forEach(el=>{
            el.index = index++;
            el.text = el.content.map(el=>{return el.text}).join("<br>");
        })
    },
    // Dialog Area ----------------------------------------- [Start]
    showtLastmeetDialog() {
      this.lastmeetDialog = true;
    },
    onEmitLastmeetDialog(val){
      if(val == "New"){
        this.loading = true;
        this.isOver = false;
        this.tab = 1;
        this.meeting_data = null;
        this.messageData = [];
        this.jot_field_definition = '';
        this.meeting_summary = null;
        parent.postMessage({
            isUpday:true,
            from: "ReMeet",
            type:"New"
        }, "*")
      }else if(val == "Last"){
        this.isOver = false;
        parent.postMessage({
            isUpday:true,
            from: "ReMeet",
            type:"Last",
            value: this.meeting_data.id
        }, "*")
      }else if(!val){
        parent.postMessage({
            isUpday:true,
            from: "ReMeet",
            type:"Over"
        }, "*")
        // window.removeEventListener('message', this.receiveMessage);
      }
      this.lastmeetDialog = false;
    },
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },
    onEmitMessageDialog(val) {
      if(val){
        if(this.isLogout){
          this.logout();
        }
      }
      this.isLogout = false;
      this.messageDialog = false;
    },
  },
  watch:{
    async "userData"(){
      if(this.userData && this.userData['access']){
        let jot_time = 15000;
        this.token = this.userData['access'];
        this.exp = this.userData['exp'];
        this.tenant = this.userData['tenant'];
        await (this.upMeetingService = new UpSideService(this.tenant,this.token));
        await this.upMeetingService.aiBotDetail("8718ceac-b47f-4a33-8447-ab4c2dce5b78").then((response)=>{
          this.knowledgeList = response.data.linked_bots_and_knowledge_set.map(item=>{return item.knowledge_lib_id});
          this.knowledgeList.forEach(el=>this.$set(el,"selected",true));
        })
        this.upMeetingService.getUPGPT_COPILOT().then((response)=>{
            jot_time = response.data.jot_time ? response.data.jot_time:jot_time;
        }).then(()=>{
          parent.postMessage({
              isUpday:true,
              from: "UpSide_After_Login",
              type:"has_login",
              value: [jot_time,this.userData]
          }, "*")
          this.getAiNoteBookList();
          this.loading = false;
        })
      }else{
        this.logout();
      }
    },
  }
};
</script>

<style lang="scss">
  .upmeeting_select{
    .ai_notebook_select{
      .v-select__slot{
        min-width: 160px;
        max-height: 22px;
        height: 22px;
        border: 1px solid gray;
        input{
          padding: 0px 0px 0px 10px;
        }
      }
    }
  }
  .warn-border{
    border:1px solid #d6d5d5;
    border-left:4px solid #59abb6;
    border-radius: 5px
  }
  .fontTeams{
    font-size:0.8rem !important;
  }
  .ellipsis-2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }
  .answer-card{
    background-color: #e6f0fd !important;
    border: none !important;
  }
  .references-card{
    box-shadow: 1px 2px 2px #e0dfdf !important;
  }
  .relation-score{
    font-family: sans-serif;
    font-weight: 700;
  }
  // .aisearch-bar{
  //   padding-top: 10px !important;
  // }
  .aisearch-bar.v-text-field .v-input__control .v-input__slot {
    height: 35px;
    font-size: 14.4px;
    border:2px solid #dbdada !important;
  }
  
  @keyframes ai-loading-circular-change {
      0% {
          stroke-dasharray: 1, 200;
          stroke-dashoffset: 0px;
          stroke:v-bind('loagindColor.a');
      }
      25% {
          stroke:v-bind('loagindColor.b');
      }
      50% {
          stroke-dasharray: 100, 200;
          stroke-dashoffset: -15px;
          stroke:v-bind('loagindColor.c');
      }
      75% {
          stroke:v-bind('loagindColor.d');
      }
      100% {
          stroke-dasharray: 100, 200;
          stroke-dashoffset: -124px;
          stroke:v-bind('loagindColor.e');
      }
  }
  .ai-loading-circular.v-progress-circular--indeterminate .v-progress-circular__overlay{
    animation: ai-loading-circular-change 1.4s ease-in-out infinite;
  }
  .ai-shot-img{
    margin-top: -4px;
  }
  .text-gray {
    color: #b9b9b9 !important;
    &:hover{
      color: #727272 !important;
    }
  }

</style>
<style scoped src="../../static/loginPage/plugins.css"></style>
<style scoped src="../../static/loginPage/common.css"></style>
<style scoped src="../../static/loginPage/login.css"></style>